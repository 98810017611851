import './App.scss';
import Header from './Componants/Header';
import Footer from './Componants/Footer';
import Main from './Componants/Main';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  MuiThemeProvider,
  createMuiTheme,
  makeStyles,
} from '@material-ui/core/styles';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles((theme) => ({
  root: {
    paddin: 0,
    margin: 0,
    boxSize: 'border-box',
    backgroundColor: theme.palette.grey[200],
  },
}));

function App(props) {
  // Or Create your Own theme:
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#263238',
      },
      secondary: {
        main: '#e14b05',
      },
    },
  });

  const classes = useStyles();
  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <ToastContainer />
        <Router>
          <Header />
          <Main />
          <Footer />
        </Router>
      </div>
    </MuiThemeProvider>
  );
}

export default App;
