import { useEffect, useState } from 'react';
import axios from 'axios';
import base_url from './bootapi';
import { useSelector } from 'react-redux';

const useProductfetch = (pageNumber, urlpath) => {
  const filterColor = useSelector((state) => state.filterColor);
  const filterAria = useSelector((state) => state.filterAria);
  const filterStitch = useSelector((state) => state.filterStitch);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [product, setProduct] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const limit = 8;

  useEffect(() => {
    setProduct([]);
  }, [filterColor, filterAria, filterStitch]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    let cancel;

    //axios.get(`${base_url}/Product`, { headers: { "x-api-key": "Rudra@0646", } }, cancelToken: new axios.CancelToken((c) => (cancel = c))
    axios({
      method: 'GET',
      headers: {
        'x-api-key': 'Rudra@0646',
        'content-type': 'application/json',
      },
      url: `${base_url}/Product`,
      params: {
        limit: limit,
        start: pageNumber,
        urlpath: urlpath,
        color: filterColor,
        aria: filterAria,
        stitch: filterStitch,
      },
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((res) => {
        setProduct((prevBooks) => {
          return [...new Set([...prevBooks, ...res.data])];
        });
        setHasMore(res.data.length > 0);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        if (axios.isCancel(e)) return;
        setError(true);
      });
    return () => cancel();
    // eslint-disable-next-line
  }, [limit, pageNumber, urlpath, filterColor, filterAria, filterStitch]);
  return { loading, error, product, hasMore };
};

export default useProductfetch;
