import React, { useEffect, useState } from 'react';
import { Card, Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FavoriteIcon from '@material-ui/icons/Favorite';
import GetAppIcon from '@material-ui/icons/GetApp';
import SimpleDialog from '../Componants/SimpleDialog';

import { useSelector } from 'react-redux';
import DownloadFiles from './DownloadFiles';
import axios from 'axios';
import base_url from '../api/bootapi';
import { useParams } from 'react-router-dom';

const SortDetailsBox = (props) => {
  const userislogin = useSelector((state) => state.userdologin);
  const userDetail = useSelector((state) => state.userDetails);
  const { id } = useParams();
  let price = '';
  const [down_limits, setDown_limits] = useState(0);

  useEffect(() => {
    const getDownloadLimit = () => {
      axios({
        method: 'GET',
        headers: {
          'x-api-key': 'Rudra@0646',
          'content-type': 'application/json',
        },
        url: `${base_url}/Userdetail?uid=${userDetail.id}`,
      })
        .then((response) => {
          //success
          setDown_limits(response.data[0].down_limit);
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getDownloadLimit();
  }, []);

  if (props.details.price === '0') {
    price = (
      <p className="free-text-line">
        <b>Design Price:</b>
        <b className="free-text" color="Red">
          Free
        </b>
      </p>
    );
  }

  const getDownloadBtn = () => {
    console.log(down_limits);
    if (down_limits === '0') {
      return <SimpleDialog />;
    } else {
      return (
        <DownloadFiles
          did={props.prodid}
          uid={userDetail.id}
          uemail={userDetail.email}
        />
      );
    }
  };

  return (
    <>
      <Card className="details_section">
        <h1 className="single-title">{props.details.title}</h1>
        <p className="single-id">
          <b>Design ID:</b> {props.prodid}
        </p>
        {price}
        <p className="free-text-line">
          <b>Machine Type:</b> {props.details.sub_cat}
        </p>

        {userislogin === 'FALSE' ? (
          <Button
            color="secondary"
            className="download-button"
            variant="contained"
            startIcon={<GetAppIcon />}
            onClick={props.download}
          >
            Download
          </Button>
        ) : (
          getDownloadBtn()
        )}

        <Button
          color="secondary"
          className="adfavorit-button"
          variant="outlined"
          startIcon={<FavoriteIcon />}
        >
          Add To Favorit
        </Button>

        <h3 className="table-title-stitch">Embroidery Design Stitch Details</h3>
        <TableContainer className="table-stitch" component={Paper}>
          <Table
            className={props.detCss.table}
            size="small"
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Stitch</TableCell>
                <TableCell align="right">Color&nbsp;(g)</TableCell>
                <TableCell align="right">Aria&nbsp;(mm)</TableCell>
                <TableCell align="right">Height&nbsp;(mm)</TableCell>
                <TableCell align="right">Width&nbsp;(mm)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.imgData.map((row) => (
                <TableRow key={row.file_name}>
                  <TableCell component="th" scope="row">
                    {row.file_name}
                  </TableCell>
                  <TableCell align="right">{row.stitch}</TableCell>
                  <TableCell align="right">{row.color}</TableCell>
                  <TableCell align="right">{row.area}mm</TableCell>
                  <TableCell align="right">{row.height}mm</TableCell>
                  <TableCell align="right">{row.width}mm</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default SortDetailsBox;
