import { Box } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

const Account = () => {
  // eslint-disable-next-line
  const userData = useSelector((state) => state.userDetails);
  // console.log(userData);
  return (
    <div>
      <Box className="main-container" mt={8}>
        <h1>User Are Loged in</h1>
        <h2>Hello {userData.email}</h2>
        <NavLink to="/logout">LogOut</NavLink>
      </Box>
    </div>
  );
};

export default Account;
