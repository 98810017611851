import React, { useCallback, useRef, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import useProductfetch from "../api/useProductfetch";
import Product from "./Product";
import { CircularProgress, Container, Box } from "@material-ui/core";
import SidebarShop from "./SidebarShop";


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    flexGrow: 1,
  },
  shopContainer: {
    marginTop: theme.spacing(1),
    minHeight: "100VH",
  },
  stitckyTop: {
    position: "fixed",
    width: "100%",
    zIndex: theme.zIndex.appBar-1,
    top: 105,
    left:0,
    height:"100VH",
    overflow: "scroll",
  },
  designDisplySec:{
    position: "fixed",
    width: "100%",
    zIndex: theme.zIndex.appBar-1,
    top: 105,
    right:0,
    height:"100VH",
    overflowY: "scroll",

  }
}));

const Shop = () => {
  const classes = useStyles();
  const urlPath = window.location.pathname;
  const [pageNumber, setPageNumber] = useState(1);
  const { product, hasMore, loading, error } = useProductfetch(
    pageNumber,
    urlPath
  );

  useEffect(() => {
    document.title = `${urlPath.substr(1)} - GetEmb.com`;
    // eslint-disable-next-line
  }, []);

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 8);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  // function handleSearch(e) {
  //   setPageNumber(0);
  // }
  return (
    <>
      <div className={classes.root}>
        <Container className={classes.shopContainer}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={3} className={classes.stitckyTop}>
              <SidebarShop/>
            </Grid>
            <Grid item xs={12} sm={9} className={classes.designDisplySec}>
              <Grid container spacing={1}>
                {product.map((book, index) => {
                  if (product.length === index + 1) {
                    return <div ref={lastBookElementRef} key={book.id}></div>;
                    //return <Product ref={lastBookElementRef} key={book.id} course={book} />
                  } else {
                    // return <div key={book.id}>{book.id}</div>
                    return <Product key={index} course={book} />;
                  }
                })}
              </Grid>

              <div align={"Center"}>
                <Box m={3}>{loading && <CircularProgress />}</Box>
              </div>
              <div>{error && "Oh! No More Designs Found"}</div>
            </Grid>
            <Grid container spacing={1} xs={12} sm={10}></Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default Shop;
