import React from "react";
import { Card } from "@material-ui/core";
import ImageGallery from "react-image-gallery";

const Imagesbox = (props) => {
  return (
    <>
      <Card className={props.imageCss}>
        <ImageGallery items={props.imgData} thumbnailPosition="left" />
      </Card>
    </>
  );
};

export default Imagesbox;
