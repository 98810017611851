import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { blue } from '@material-ui/core/colors';
import { Box, Container, Grid } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';

const emails = ['username@gmail.com', 'user02@gmail.com'];

function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Box p={2}>
        <Container>
          <Grid container spacing={2}>
            <Grid sm={12}>
              <h1>Please Buy Package</h1>
              <p>
                Sorry you don't have any active package now please buy any
                package then you can download any design until you package
                limits
              </p>
            </Grid>

            <Grid sm={6}>
              <Button
                color="secondary"
                className="download-button"
                variant="contained"
              >
                Buy Package
              </Button>
            </Grid>
            <Grid sm={6} align={'right'}>
              <Button
                color="secondary"
                className="download-button"
                variant="outlined"
                onClick={() => handleClose()}
              >
                Not Now
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function SimpleDialogDemo() {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <>
      <Button
        color="secondary"
        className="download-button"
        variant="contained"
        startIcon={<CloudDownload />}
        onClick={handleClickOpen}
      >
        Download
      </Button>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />
    </>
  );
}
