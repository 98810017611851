import React from "react";
import HomeIcon from "@material-ui/icons/Home";
const Home = () => {
  return (
    <>
      <h1>
        <HomeIcon /> Home Page
      </h1>
    </>
  );
};

export default Home;
