import React from "react";
// import prodImg1 from "../imges/prod1.JPG";
import { Card, Grid, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// import "./Product.scss";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 0,
    margin: 0,
    boxSizing: "border-box",
  },
  free: {
    color: theme.palette.secondary.main,
  },
  pricerow: {
    padding: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  // prod_card: {
  //   Height: 350,
  // },
  imagebox: {
    disply: "flex",
    backgroundColor: "#fff",
    maxHeight: 300,
    boxSizung: "border-box",
    overflow: "hidden",
  },
  proImg: {
    height: 300,
    backgroundColor: "#fff",
    width: "100%",
    objectFit: "scale-down",
    // transition: "transform 0.15s ease-in-out",
    "&:hover": {
      // zoom: 1.5,
      transition: "transform .5s linear",
      transform: "scale3d(1.5, 1.5, 1)",
    },
  },
}));

const Product = ({ course }) => {
  const classes = useStyles();
  const product_price = (price) => {
    if (price === "0") {
      return <b className={classes.free}> Free </b>;
    }
  };

  return (
    <Grid item md={3} sm={4} xs={6} className={classes.root}>
      <Card className={classes.prod_card}>
        <Link to={"embroidery_design/" + course.id}>
          <div className={classes.imagebox}>
            <img
              src={course.img}
              alt={`embroidery design id ${course.id}`}
              className={classes.proImg}
            />
          </div>
        </Link>
        <Grid
          className={classes.pricerow}
          container
          spacing={1}
          components={Box}
        >
          <Grid item xs={6}>
            ID: {course.id}
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            {product_price(course.price)}
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default Product;
