import React from "react";
import { Redirect } from "react-router-dom";

const Protected = (props) => {
  const Cmp = props.cmp;
  var auth = localStorage.getItem("token");
  //console.warn("authTOken:" + auth)

  return <div>{auth ? <Cmp /> : <Redirect to="login" />}</div>;
};

export default Protected;
