import userdologin from "./loginData";
import savedUrl from "./savedUrl";
import userDetails from "./userDetails";
import { combineReducers } from "redux";
import filterColor from "./filterColor";
import filterStitch from "./filterStitch";
import filterAria from "./filterAria";

const rootReducer = combineReducers({
  userdologin,
  savedUrl,
  userDetails,
  filterColor,
  filterStitch,
  filterAria,
});

export default rootReducer;
