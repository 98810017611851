import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import GetAppIcon from '@material-ui/icons/GetApp';
import { blue } from '@material-ui/core/colors';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { saveAs } from 'file-saver';
import axios from 'axios';
import base_url from '../api/bootapi';
import {
  CloudDownload,
  DomainTwoTone,
  FontDownload,
  FontDownloadOutlined,
} from '@material-ui/icons';
import { toast } from 'react-toastify';

const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function SimpleDialog(props) {
  // console.log(props.downs);
  // eslint-disable-next-line
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  // eslint-disable-next-line
  const handleListItemClick = (value) => {
    onClose(value);
  };

  // find file list

  //const [DownloadStart, setDownloadStart] = useState(false);

  const saveFile = (url, name) => {
    console.log('Save file run');
    // updateDownload(props.uemail, props.did);

    saveAs(`${url}`, `GetEmb-${props.did}-${name}.EMB`, { autoBom: true });
  };

  return (
    <Dialog
      onClose={handleClose}
      // aria-labelledby="simple-dialog-title"
      aria-labelledby="alert-dialog-slide-description"
      open={open}
    >
      <Box p={2}>
        <Container>
          <Grid container spacing={2}>
            <Grid sm={12}>
              <Box m={1}>
                {props.downs.map((des, index) => {
                  return (
                    <Grid container>
                      <Grid sm={2}>
                        <Typography>{des.file_name}</Typography>
                      </Grid>

                      <Grid sm={2}>
                        <Button
                          color="secondary"
                          className="download-button"
                          variant="contained"
                          onClick={() => saveFile(des.emb_path, des.file_name)}
                        >
                          <CloudDownload /> .EMB
                        </Button>
                      </Grid>

                      <Grid sm={2}>
                        <Button
                          color="secondary"
                          className="download-button"
                          variant="contained"
                          onClick={() => saveFile(des.dst_path, des.file_name)}
                        >
                          <CloudDownload /> .DST
                        </Button>
                      </Grid>

                      <Grid sm={2}>
                        <Button
                          color="secondary"
                          className="download-button"
                          variant="contained"
                          onClick={() => saveFile(des.jef_path, des.file_name)}
                        >
                          <CloudDownload /> .JEF
                        </Button>
                      </Grid>

                      <Grid sm={2}>
                        <Button
                          color="secondary"
                          className="download-button"
                          variant="contained"
                          onClick={() => saveFile(des.pes_path, des.file_name)}
                        >
                          <CloudDownload /> .PES
                        </Button>
                      </Grid>
                    </Grid>
                  );
                  //return <Product ref={lastBookElementRef} key={book.id} course={book} />
                })}
              </Box>
            </Grid>
            <Grid sm={6}>
              <Button
                color="secondary"
                className="download-button"
                variant="contained"
              >
                Buy Package
              </Button>
            </Grid>
            <Grid sm={6} align={'right'}>
              <Button
                color="secondary"
                className="download-button"
                variant="outlined"
                onClick={() => handleClose()}
              >
                Not Now
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function DownloadFiles(props) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const updateDownload = () => {
    // this is not working
    console.log('Des Update file run');
    let formdata = new FormData();
    formdata.append('email', props.uemail);
    formdata.append('did', props.did);

    axios({
      method: 'POST',
      headers: {
        'x-api-key': 'Rudra@0646',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      url: 'https://app.embfree.in/api/PayWithPackage',
      data: formdata,
    })
      .then((response) => {
        //success fully login set user Login Redux
        if (response.data.message === 'Package Payment SuccessFully') {
          toast.success('Downloadin progress...', {
            position: 'bottom-right',
          });
        } else if (response.data.message === 'Design Already Downloaded.') {
          toast.success('Design Allredy Downloaded Please go My Download...', {
            position: 'bottom-right',
          });
        } else {
          toast.error('Somthing Wrong. Please Contact Us on HelpLine', {
            position: 'bottom-right',
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ID = props.designId;
  const [downloads, setdownloads] = useState([]);

  useEffect(() => {
    const getdownload = () => {
      console.log('get download file run');
      axios({
        method: 'GET',
        headers: {
          'x-api-key': 'Rudra@0646',
          'content-type': 'application/json',
        },
        url: `${base_url}/Product/Download/${props.did}`,
      })
        .then((response) => {
          //success
          // console.log(response.data);
          setdownloads(response.data);
        })
        .catch((error) => {
          //console.log(error);
        });
    };
    updateDownload();
    getdownload();
  }, [props.did]);

  return (
    <>
      <Button
        color="secondary"
        className="download-button"
        variant="contained"
        startIcon={<GetAppIcon />}
        onClick={handleClickOpen}
      >
        Download
      </Button>

      <SimpleDialog
        did={props.did}
        uid={props.uid}
        downs={downloads}
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        uemail={props.uemail}
      />
    </>
  );
}
