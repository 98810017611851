const initialStat = "/";
const savedUrl = (state = initialStat, action) => {
  switch (action.type) {
    case "SAVE_LAST_URL":
      return action.curl;

    default:
      return state;
  }
};
export default savedUrl;
