import React from "react";
import Navbar from "./Navbar";
import { makeStyles } from "@material-ui/core/styles";

// style
const useStyles = makeStyles((theme) => ({
  header: {
    padding: 0,
    margin: 0,
    boxSizing: "border-box",
  },
  // paper: {
  //   padding: theme.spacing(0),
  //   textAlign: "center",
  //   color: theme.palette.text.secondary,
  // },
}));

const Header = () => {
  const classes = useStyles();
  return (
    <header className={classes.header}>
      <Navbar />
      {/* <Designmenu / */}
    </header>
  );
};

export default Header;
