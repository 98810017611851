import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Container, Box } from '@material-ui/core';
import 'react-image-gallery/styles/css/image-gallery.css';
import './single.scss';
import axios from 'axios';
import base_url from '../api/bootapi';
import Imagesbox from './Imagesbox';
import SortDetailsBox from './SortDetailsBox';
import FullDetails from './FullDetails';
import { userLastUrl } from '../actions/index';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '70px',
  },

  image_section: {
    padding: '10px',
    height: '100%',
  },
  table: {
    minWidth: 650,
  },
}));

const Single = () => {
  const dispatch = useDispatch();
  const userislogin = useSelector((state) => state.userdologin);
  let history = useHistory();
  const classes = useStyles();
  const { id } = useParams();
  const [details, setDetails] = useState([]);
  const [imgdetails, setimgDetails] = useState([]);

  useEffect(() => {
    document.title = `Embroidery Designs ID-${id} GetEmb.com`;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    //coll api from server
    const getaDetailFromServer = () => {
      axios({
        method: 'GET',
        headers: {
          'x-api-key': 'Rudra@0646',
          'content-type': 'application/json',
        },
        url: `${base_url}/Product/${id}`,
      })
        .then((response) => {
          //success
          // console.log(response.data);
          setDetails(response.data);
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    const getallDetail = () => {
      axios({
        method: 'GET',
        headers: {
          'x-api-key': 'Rudra@0646',
          'content-type': 'application/json',
        },
        url: `${base_url}/Product/Details/${id}`,
      })
        .then((response) => {
          //success
          // console.log(response.data);
          setimgDetails(response.data);
        })
        .catch((error) => {
          //console.log(error);
        });
    };
    getaDetailFromServer();
    getallDetail();
  }, [id]);

  const imagess = [];

  imgdetails.map((item) =>
    imagess.push({ original: item.file_path, thumbnail: item.file_path })
  );

  const downloadbtnHandle = () => {
    //save this ural to lasturl
    dispatch(userLastUrl(window.location.pathname));

    // check user login or not
    if (userislogin === 'FALSE') {
      history.push('/login');
    }

    //check user have download balance?

    // if yse ->> show download  filese poupup

    // if no ->> show Buy package options
  };

  return (
    <div className={classes.root}>
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} className="imageGrid">
            <Imagesbox imgData={imagess} imageCss={classes.image_section} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SortDetailsBox
              download={downloadbtnHandle}
              details={details}
              detCss={classes.table}
              imgData={imgdetails}
              prodid={id}
            />
          </Grid>
        </Grid>
      </Container>
      <Box mt={4}></Box>
      <Container>
        <FullDetails details={details} imgData={imgdetails} />
      </Container>
    </div>
  );
};

export default Single;
