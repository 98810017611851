import React from "react";
import Home from "./Home";
import { Route, Switch } from "react-router-dom";
import Shop from "./Shop";
import About from "./About";
import Contact from "./Contact";
import Single from "./Single";
import { makeStyles } from "@material-ui/core/styles";
import Login from "./Login";
import Account from "./Account";
import Protected from "./Protected";
import Logout from "./Logout";
import Register from "./Register";
import Designmenu from "./Designmenu";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "0px",
    flexGrow: 1,
    //marginTop: theme.spacing(1),
  },
}));

const Main = () => {
  const classes = useStyles();

  return (
    <main className={classes.root}>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/embroidery_design">
          <Designmenu />
          <Shop key="embroidery_design" />
        </Route>
        <Route exact path="/multi_designs">
          <Designmenu />
          <Shop key="multi_designs" />
        </Route>
        <Route exact path="/codding_designs">
          <Designmenu />
          <Shop key="codding design" />
        </Route>
        <Route exact path="/sequence_designs">
          <Designmenu />
          <Shop key="sequence_designs" />
        </Route>
        <Route exact path="/chainstich_designs">
          <Designmenu />
          <Shop key="chainstich_designs" />
        </Route>
        <Route exact path="/small_machine_design">
          <Designmenu />
          <Shop key="small_machine_design" />
        </Route>
        <Route exact path="/embroidery_design/:id">
          <Single />
        </Route>

        <Route exact path="/about">
          {/* <About /> */}
          <Protected cmp={About} />
        </Route>
        <Route exact path="/contact">
          <Contact />
        </Route>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/register">
          <Register />
        </Route>
        <Route exact path="/logout">
          <Logout />
        </Route>
        <Route exact path="/account">
          {/* <Account /> */}
          <Protected cmp={Account} />
        </Route>
      </Switch>
    </main>
  );
};

export default Main;
