const initialStat = "";
const filterStitch = (state = initialStat, action) => {
  switch (action.type) {
    case "FILTER_STITCH":
      return action.stitch;
    default:
      return state;
  }
};
export default filterStitch;
