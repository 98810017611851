const initialStat = "";
const filterAria = (state = initialStat, action) => {
  switch (action.type) {
    case "FILTER_ARIA":
      return action.aria;
    default:
      return state;
  }
};
export default filterAria;
