const initialStat = "FALSE";
const userdologin = (state = initialStat, action) => {
  switch (action.type) {
    case "USERLOGIN":
      return "TRUE";
    case "USERLOGOUT":
      return "FALSE";
    default:
      return state;
  }
};
export default userdologin;
