import React, { useState } from "react";
import { MenuItem, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(8),
      possition: "fixed",
      top: 0,
      // zIndex: theme.zIndex.AppBar + 5,

      display: "flex",
      width: "100%",
      justifyContent: "space-around",
    },
  },
  stitckyTop: {
    zIndex: "1200",
    position: "sticky",
    // zIndex: theme.zIndex.appBar - 1,
    top: 60,
  },
}));

const Designmenu = () => {
  const classes = useStyles();
  const [selectedMType, setSelectedMType] = useState("All Latest Designs");

  return (
    <div className={classes.stitckyTop}>
      <Paper
        square
        className={classes.sectionDesktop}
        style={{ position: "sticky", top: 0 }}
      >
        <MenuItem
          color="inherit"
          component={Link}
          to="/embroidery_design"
          style={
            selectedMType === "All Latest Designs"
              ? {
                  borderBottom: "2px solid #e14b05",
                }
              : null
          }
          onClick={() => setSelectedMType("All Latest Designs")}
        >
          All Latest Designs
        </MenuItem>
        <MenuItem
          color="inherit"
          component={Link}
          to="/multi_designs"
          style={
            selectedMType === "Multi Designs"
              ? { borderBottom: "2px solid #e14b05" }
              : null
          }
          onClick={() => setSelectedMType("Multi Designs")}
        >
          Multi Designs
        </MenuItem>
        <MenuItem
          color="inherit"
          component={Link}
          to="/sequence_designs"
          style={
            selectedMType === "Sequence Designs"
              ? { borderBottom: "2px solid #e14b05" }
              : null
          }
          onClick={() => setSelectedMType("Sequence Designs")}
        >
          Sequence Designs
        </MenuItem>
        <MenuItem
          color="inherit"
          component={Link}
          to="/codding_designs"
          style={
            selectedMType === "Codding Designs"
              ? { borderBottom: "2px solid #e14b05" }
              : null
          }
          onClick={() => setSelectedMType("Codding Designs")}
        >
          Codding Designs
        </MenuItem>
        <MenuItem
          color="inherit"
          component={Link}
          to="/chainstich_designs"
          style={
            selectedMType === "Chain Stich Design"
              ? { borderBottom: "2px solid #e14b05" }
              : null
          }
          onClick={() => setSelectedMType("Chain Stich Design")}
        >
          Chain Stich Design
        </MenuItem>

        <MenuItem
          color="inherit"
          component={Link}
          to="/small_machine_design"
          style={
            selectedMType === "Small Machine Designs"
              ? { borderBottom: "2px solid #e14b05" }
              : null
          }
          onClick={() => setSelectedMType("Small Machine Designs")}
        >
          Small Machine Designs
        </MenuItem>
        {/* </div> */}
      </Paper>
    </div>
  );
};

export default Designmenu;
