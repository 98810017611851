export const userlogin = () => {
  return {
    type: "USERLOGIN",
  };
};

//use for login to save user details
export const setUserDetails = (data) => {
  return {
    type: "SET_USERS_DETAILS",
    data,
  };
};

//use for logout to remove user details
export const removeUserDetails = () => {
  return {
    type: "REMOVE_USERS_DETAILS",
  };
};

export const userlogout = () => {
  return {
    type: "USERLOGOUT",
  };
};

export const userLastUrl = (curl) => {
  return {
    type: "SAVE_LAST_URL",
    curl,
  };
};

export const setMTyep = (mtype) => {
  return {
    type: "SELECT_M_TYPE",
    mtype,
  };
};

export const filterByColor = (color) => {
  return {
    type: "FILTER_COLORS",
    color,
  };
};

export const filterByStitch = (stitch) => {
  return {
    type: "FILTER_STITCH",
    stitch,
  };
};

export const filterByAria = (aria) => {
  return {
    type: "FILTER_ARIA",
    aria,
  };
};

export const filterByCat = (cat) => {
  return {
    type: "FILTER_CAT",
    cat,
  };
};
