const initialStat = [];
const userDetails = (state = initialStat, action) => {
  switch (action.type) {
    case "SET_USERS_DETAILS":
      return action.data;

    case "REMOVE_USERS_DETAILS":
      return [];

    default:
      return state;
  }
};
export default userDetails;
