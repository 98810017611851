import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeUserDetails, userlogout } from "../actions/index";

const Logout = () => {
  localStorage.removeItem("token");
  const dispatch = useDispatch();
  dispatch(userlogout());
  dispatch(removeUserDetails());

  return (
    <div>
      <NavLink to="/">Go TO Home Page</NavLink>
    </div>
  );
};

export default Logout;
