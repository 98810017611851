import React from 'react';
import { Grid, Card, Box, Typography, capitalize } from '@material-ui/core';

const FullDetails = (props) => {
  return (
    <div>
      <>
        <Grid item xs={12}>
          <Card>
            <Box p={1}>
              <Typography>
                <h1 align={'Center'}>{capitalize('' + props.details.title)}</h1>
                <p>
                  Embroidery designs for {props.details.cat_sort_name}. this
                  design is for supported computerized Multihead Embroidery
                  Machines. We Provide .EMB And .DST Files And also some Small
                  Machine Category we Provide .JEF And .PES Files. This Design
                  is For {props.details.name}. This Design supported Machine
                  Type is {props.details.sub_cat}. Some More informations is
                  Below.
                </p>
                <br />
                <ul>
                  <li>
                    Design is For {props.details.name} Here is All Details
                    Informa
                  </li>
                  <br />
                  {props.imgData.map((row) => (
                    <>
                      <li>
                        {capitalize('' + row.file_name)} Stitch is: {row.stitch}
                        ,
                      </li>
                      <li>
                        {capitalize('' + row.file_name)} Niddel Colour is:
                        {row.color},
                      </li>
                      <li>
                        {capitalize('' + row.file_name)} Height is: {row.height}
                        ,
                      </li>
                      <li>
                        {capitalize('' + row.file_name)} Width is: {row.width},
                        and
                      </li>
                      <li>
                        {capitalize('' + row.file_name)}, Machine Suppor
                        Aria(Head Interval) is: {row.area}.
                      </li>
                      <br />
                    </>
                  ))}
                </ul>
              </Typography>
            </Box>
          </Card>
        </Grid>
      </>
    </div>
  );
};

export default FullDetails;
