import {
  Grid,
  RadioGroup,
  FormControl,
  FormLabel,
  Radio,
  FormControlLabel,
  Card,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Container,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import {
  filterByStitch,
  filterByColor,
  filterByAria,
  filterByCat,
} from '../actions/index';

const useStyles = makeStyles((theme) => ({
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(8),
      possition: 'fixed',
      top: 0,
      // zIndex: theme.zIndex.AppBar + 5,

      display: 'flex',
      width: '100%',
      justifyContent: 'space-around',
    },
  },
}));

const SidebarShop = () => {
  const despetch = useDispatch();

  const [expanded, setExpanded] = React.useState(false);

  const [filter, setFilter] = useState({});

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const classes = useStyles();

  // const label = { inputProps: { 'aria-label': 'Checkbox demo', 'lable': "Multi Design" } };
  // const handleChangeStitch= (e) => {
  //   // setFilter({ ...filter, [e.target.name]: e.target.value, })
  //   despetch(filterByStitch(e.target.value))
  //   console.log(filter)
  // };

  const handleChangeStitch = (e) => {
    despetch(filterByStitch(e.target.value));
  };

  const handleChangeColor = (e) => {
    despetch(filterByColor(e.target.value));
  };

  const handleChangeAria = (e) => {
    despetch(filterByAria(e.target.value));
  };

  useEffect(() => {
    return () => {};
  }, [filter]);
  return (
    <>
      <Container className={classes.stitckyTop} m={0} p={0}>
        <Grid container m={0} p={0}>
          <Grid item xs={12} sm={12}>
            <Card m={0} p={0}>
              <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    Stitch Filter
                  </Typography>
                  {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                  {/* <FormGroup>
                      <FormControlLabel control={<Checkbox value='00 - 20000' />} label="00 - 20000" />
                      <FormControlLabel control={<Checkbox />} label="20000 - 40000" />
                      <FormControlLabel control={<Checkbox />} label="40000 - 60000" />
                      <FormControlLabel control={<Checkbox />} label="60000- 80000" />
                      <FormControlLabel control={<Checkbox defaultChecked />} label="80000 - 100000" />
                      <FormControlLabel control={<Checkbox />} label="100000- 150000" />
                      <FormControlLabel control={<Checkbox />} label="150000- 20000" />
                      {/* <FormControlLabel disabled control={<Checkbox />} label="Disabled" /> 
                    </FormGroup> */}
                  <FormControl component="fieldset">
                    <FormLabel component="legend" style={{ fontSize: '12px' }}>
                      Select stitich range
                    </FormLabel>
                    <RadioGroup
                      aria-label="color"
                      defaultValue=""
                      name="stitch"
                      onChange={handleChangeStitch}
                    >
                      <FormControlLabel
                        value="1000 - 20000"
                        control={<Radio />}
                        label="1000 - 20000"
                      />
                      <FormControlLabel
                        value="20000 - 40000"
                        control={<Radio />}
                        label="20000 - 40000"
                      />
                      <FormControlLabel
                        value="40000 - 60000"
                        control={<Radio />}
                        label="40000 - 60000"
                      />
                      <FormControlLabel
                        value="60000 - 80000"
                        control={<Radio />}
                        label="60000 - 80000"
                      />
                      <FormControlLabel
                        value="80000 - 100000"
                        control={<Radio />}
                        label="80000 - 100000"
                      />
                      <FormControlLabel
                        value="100000 - 150000"
                        control={<Radio />}
                        label="100000 - 150000"
                      />
                      <FormControlLabel
                        value="150000 - 200000"
                        control={<Radio />}
                        label="150000 - 200000"
                      />
                      <FormControlLabel
                        value="200000 - 250000"
                        control={<Radio />}
                        label="200000 - 250000"
                      />
                      <FormControlLabel
                        value="250000 -300000"
                        control={<Radio />}
                        label="250000 -300000"
                      />
                    </RadioGroup>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    Needle Colour Filter
                  </Typography>
                  {/* <Typography sx={{ color: 'text.secondary' }}>
                    You are currently not an owner
                  </Typography> */}
                </AccordionSummary>
                <AccordionDetails>
                  {/* <RadioGroup name="Color">
                    <FormControlLabel onChange={handleChangeColor} control={<Checkbox  value='1' />} label="1" />
                    <FormControlLabel onChange={handleChangeColor} control={<Checkbox  value='2' />} label="2" />
                    <FormControlLabel onChange={handleChangeColor} control={<Checkbox  value='3' />} label="3" />
                    <FormControlLabel onChange={handleChangeColor} control={<Checkbox  value='4' />} label="4" />
                    <FormControlLabel onChange={handleChangeColor} control={<Checkbox  value='5' />} label="5" />
                    <FormControlLabel onChange={handleChangeColor} control={<Checkbox  value='6' />} label="6" />
                    <FormControlLabel onChange={handleChangeColor} control={<Checkbox  value='7' />} label="7" />
                    <FormControlLabel onChange={handleChangeColor} control={<Checkbox  value='8' />} label="8" />
                    <FormControlLabel onChange={handleChangeColor} control={<Checkbox  value='9'  />} label="9" />
                    <FormControlLabel onChange={handleChangeColor} control={<Checkbox  value='10' />} label="10" />
                    <FormControlLabel onChange={handleChangeColor} control={<Checkbox  value='11' />} label="11" />
                  </RadioGroup> */}
                  <FormControl component="fieldset">
                    <FormLabel component="legend" style={{ fontSize: '12px' }}>
                      Select needle colors
                    </FormLabel>
                    <RadioGroup
                      aria-label="color"
                      defaultValue=""
                      name="color"
                      onChange={handleChangeColor}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="1"
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label="2"
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio />}
                        label="3"
                      />
                      <FormControlLabel
                        value="4"
                        control={<Radio />}
                        label="4"
                      />
                      <FormControlLabel
                        value="5"
                        control={<Radio />}
                        label="5"
                      />
                      <FormControlLabel
                        value="6"
                        control={<Radio />}
                        label="6"
                      />
                      <FormControlLabel
                        value="7"
                        control={<Radio />}
                        label="7"
                      />
                      <FormControlLabel
                        value="8"
                        control={<Radio />}
                        label="8"
                      />
                      <FormControlLabel
                        value="9"
                        control={<Radio />}
                        label="9"
                      />
                      <FormControlLabel
                        value="10"
                        control={<Radio />}
                        label="10"
                      />
                      <FormControlLabel
                        value="11"
                        control={<Radio />}
                        label="11"
                      />
                    </RadioGroup>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'panel3'}
                onChange={handleChange('panel3')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    Machine Aria Filter
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* <FormGroup>
                    <FormControlLabel control={<Checkbox value='125mm' />} label="125mm" />
                    <FormControlLabel control={<Checkbox value='200mm' />} label="200mm" />
                    <FormControlLabel control={<Checkbox value='250mm' />} label="250mm" />
                    <FormControlLabel control={<Checkbox value='300mm' />} label="300mm" />
                    <FormControlLabel control={<Checkbox value='350mm' />} label="350mm" />
                    <FormControlLabel control={<Checkbox value='400mm' />} label="400mm" />
                    <FormControlLabel control={<Checkbox value='450mm' />} label="450mm" />
                    <FormControlLabel control={<Checkbox value='500mm' />} label="500mm" />
                    <FormControlLabel control={<Checkbox value='550mm' />} label="550mm" />
                  </FormGroup> */}

                  <FormControl component="fieldset">
                    <FormLabel component="legend" style={{ fontSize: '12px' }}>
                      Select stitich range
                    </FormLabel>
                    <RadioGroup
                      aria-label="aria"
                      defaultValue=""
                      name="aria"
                      onChange={handleChangeAria}
                    >
                      <FormControlLabel
                        value="125mm"
                        control={<Radio />}
                        label="125mm"
                      />
                      <FormControlLabel
                        value="200mm"
                        control={<Radio />}
                        label="200mm"
                      />
                      <FormControlLabel
                        value="250mm"
                        control={<Radio />}
                        label="250mm"
                      />
                      <FormControlLabel
                        value="300mm"
                        control={<Radio />}
                        label="300mm"
                      />
                      <FormControlLabel
                        value="350mm"
                        control={<Radio />}
                        label="350mm"
                      />
                      <FormControlLabel
                        value="400mm"
                        control={<Radio />}
                        label="400mm"
                      />
                      <FormControlLabel
                        value="450mm"
                        control={<Radio />}
                        label="450mm"
                      />
                      <FormControlLabel
                        value="500mm"
                        control={<Radio />}
                        label="500mm"
                      />
                      <FormControlLabel
                        value="550mm"
                        control={<Radio />}
                        label="550mm"
                      />
                    </RadioGroup>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default SidebarShop;
