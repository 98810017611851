import React from "react";
import { Grid } from "@material-ui/core";
import "./footer.scss";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  footerWigitRow: {},
  footerLinks: {
    color: "#7e7e7e",
    textDecoration: "none",
    fontWeight: "500",
  },
  footerLastline: {
    display: "flex",
    justifyContent: "center",
  },
  footer: {
    margin: "0px",
    padding: "0px",
    boxSize: "border-box",
    width: "100%",
    backgroundColor: theme.palette.primary.dark,
    marginTop: "100px",
    color: "white",
    //position: "fixed",
    bottom: "0",
  },
}));
const Footer = () => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Grid container>
        <Grid container spacing={1} className="footerWigitRow">
          <Grid item sm={3} xs={6} p={0} className="footerbox footrbox-1">
            footer1
          </Grid>
          <Grid item sm={3} xs={6} p={0} className="footerbox footrbox-1">
            footer1
          </Grid>
          <Grid item sm={3} xs={6} p={0} className="footerbox footrbox-1">
            footer1
          </Grid>
          <Grid item sm={3} xs={6} p={0} className="footerbox footrbox-1">
            footer1
          </Grid>
        </Grid>
        <Grid item sm={12} p={1} className={classes.footerLastline}>
          Design By:
          <a href="/" className={classes.footerLinks}>
            Hitesh suhagiya
          </a>{" "}
          &copy;
          <a href="/" className={classes.footerLinks}>
            Getemb.com{" "}
          </a>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
