import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
} from "@material-ui/core";
import { Face, Fingerprint } from "@material-ui/icons";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setUserDetails, userlogin } from "../actions/index";
import axios from "axios";
import base_url from "../api/bootapi";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "70px",
    display: "flex",
    height: "85vh",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  margin: {
    margin: theme.spacing.unit * 2,
  },
  padding: {
    padding: theme.spacing.unit,
  },
  logintext: {
    textAlign: "center",
  },
}));
const Register = () => {
  const despetch = useDispatch();
  const classes = useStyles();
  let history = useHistory();
  const token = localStorage.getItem("token");
  const lastUrl = useSelector((state) => state.savedUrl);

  useEffect(() => {
    document.title = "Register - GetEmb.com";
    token ? despetch(userlogin()) : localStorage.clear();
    // eslint-disable-next-line
  }, []);

  const [authData, setAuthData] = useState({});
  // eslint-disable-next-line
  const [logiInRes, setLogiInRes] = useState([]);

  const getaDetailFromServer = (email, password) => {
    let formdata = new FormData();
    formdata.append("username", email);
    formdata.append("pass", password);

    axios({
      method: "POST",
      headers: {
        "x-api-key": "Rudra@0646",
        // "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      url: `${base_url}/Login`,
      data: formdata,
    })
      .then((response) => {
        //success fully login set user Login Redux
        if (response.data.message === "User login successfully") {
          setLogiInRes(response.data);
          despetch(userlogin());
          despetch(setUserDetails(response.data));
          history.push(lastUrl);
          localStorage.setItem("token", response.data.email);
          toast.success("Login Success full", { position: "bottom-right" });
        } else {
          toast.error("Email And Password Are Not Match!", {
            position: "bottom-right",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //  // function for submit button handler in form
  const submitForm = (e) => {
    if (authData.email === "" || authData.password === "") {
      toast.error("Please Enter Valid Value", { position: "bottom-right" });
    } else {
      const { email, password } = authData;
      getaDetailFromServer(email, password);
    }
  };
  return (
    <div className={classes.root}>
      {token ? <Redirect to={lastUrl} /> : null}
      <Paper className={classes.padding}>
        <div className={classes.margin}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12}>
              <h1 className={classes.logintext}>Register</h1>
              <p className={classes.logintext}>Create New Account</p>
            </Grid>
            <Grid item>
              <Fingerprint />
            </Grid>
            <Grid item md={true} sm={true} xs={true}>
              <TextField
                id="fname"
                label="First Name"
                type="text"
                fullWidth
                autoFocus
                required
                onChange={(e) => {
                  setAuthData({ ...authData, fname: e.target.value });
                }}
              />
            </Grid>
            <Grid item md={true} sm={true} xs={true}>
              <TextField
                id="lname"
                label="Last Name"
                type="text"
                fullWidth
                autoFocus
                required
                onChange={(e) => {
                  setAuthData({ ...authData, lname: e.target.value });
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item>
              <Face />
            </Grid>
            <Grid item md={true} sm={true} xs={true}>
              <TextField
                id="username"
                label="Email"
                type="email"
                fullWidth
                autoFocus
                required
                onChange={(e) => {
                  setAuthData({ ...authData, email: e.target.value });
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item>
              <Fingerprint />
            </Grid>
            <Grid item md={true} sm={true} xs={true}>
              <TextField
                id="username"
                label="Password"
                type="password"
                fullWidth
                required
                onChange={(e) => {
                  setAuthData({ ...authData, password: e.target.value });
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item>
              <Fingerprint />
            </Grid>
            <Grid item md={true} sm={true} xs={true}>
              <TextField
                id="rePassword"
                label="Re-Enter Password"
                type="password"
                fullWidth
                required
                onChange={(e) => {
                  setAuthData({ ...authData, repassword: e.target.value });
                }}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <FormControlLabel
                control={<Checkbox color="primary" />}
                label="Remember me"
              />
            </Grid>
            <Grid item>
              <Button
                disableFocusRipple
                disableRipple
                style={{ textTransform: "none" }}
                variant="text"
                color="primary"
              >
                Forgot password ?
              </Button>
            </Grid>
          </Grid>
          <Grid container justify="center" style={{ marginTop: "10px" }}>
            <Button
              variant="outlined"
              color="primary"
              style={{ textTransform: "none" }}
              onClick={() => submitForm()}
            >
              Register
            </Button>
          </Grid>
          <Grid container justify="center" style={{ marginTop: "10px" }}>
            <Grid item xs={12} style={{ marginTop: "10px" }}>
              <p align={"center"}>
                All ready have a account? Please Press Login
              </p>
            </Grid>
            <Button
              variant="outlined"
              color="primary"
              style={{ textTransform: "none" }}
              onClick={() => history.push("/login")}
            >
              Go To Login
            </Button>
          </Grid>
        </div>
      </Paper>
    </div>
  );
};

export default Register;
