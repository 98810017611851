const initialStat = "";
const filterColor = (state = initialStat, action) => {
  switch (action.type) {
    case "FILTER_COLORS":
      return action.color;
    default:
      return state;
  }
};
export default filterColor;
